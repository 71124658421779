.flex {
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap !important;
}

.flex-item-50 {
	width: 50%;
}
