* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	font-family: Urbanist;
}

/**
 *   food properties style
 */

.product img {
	height: 125px;
	margin-right: 30px;
}
.product .recommend {
	font-size: 14px !important;
}
.product .food-name {
	font-size: 25px !important;
}
.product .cost {
	font-size: 35px !important;
}
.product .data {
	font-size: 16px !important;
}

@media (max-width: 1200px) {
	.product img {
		height: 85px;
	}
	.product .recommend {
		font-size: 9px !important;
	}
	.product .food-name {
		font-size: 16px !important;
	}
	.product .cost {
		font-size: 24px !important;
	}
	.product .data {
		font-size: 11px !important;
	}
}

/**
 *   food properties style end
 */
